<template>
  <div class="container">
    <b-col
      lg="6"
      sm="12"
      xs="12"
      md="8"
      offset-lg="3"
      offset-md="2"
    >
      <b-card
        class="hubu-bg-red"
        :header="$t('auth.reset_password')"
        header-tag="header"
      >
        <b-form @submit="submitForm">
          <b-form-group
            id="input-group-1"
            :label="$t('auth.email_label') + ':'"
            label-for="input-2"
          >
            <b-input-group>
              <b-form-input
                id="input-2"
                v-model="$route.query.email"
                disabled
                style="margin-bottom: 1rem;"
                :label="$t('auth.email_label')"
                :placeholder="$t('auth.email_label')"
              ></b-form-input>
            </b-input-group>
          </b-form-group>
          <b-form-group
            id="input-group-1"
            :label="$t('auth.password_label') + ':'"
            label-for="input-2"
          >
            <b-input-group>
              <b-form-input
                id="input-2"
                v-model="form.password"
                :type="form.password_field.type"
                required
                minlength="6"
                :placeholder="$t('auth.password_label')"
              ></b-form-input>
              <b-input-group-append style="margin-bottom: 1rem;">
                <b-button
                  variant="outline-secondary"
                  @click="showPassword"
                >
                  <b-icon :icon="form.password_field.icon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          <div>
            <b-button
              class="hubu-btn-red mr-2"
              size="md"
              type="submit"
              variant="primary"
            >
              {{ $t("submit") }}
            </b-button>
          </div>
        </b-form>
      </b-card>
    </b-col>

    <b-modal
      id="confirm"
      :title="$t('auth.reset_password')"
      header-bg-variant="red"
      header-text-variant="white"
      body-bg-variant="white"
      body-text-variant="dark"
      footer-bg-variant="white"
      footer-text-variant="red"
    >
      <b-container fluid>
        <b-row class="mb-1 text-center">
          <b-col cols="12">
            {{ $t("reset_success") }}
          </b-col>
        </b-row>
      </b-container>

      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="outline-red"
            size="lg"
            class="float-right"
            @click="$bvModal.hide('confirm')"
          >
            {{ $t("ok") }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
//import Cookies from 'js-cookie'
export default {
  name: "Reset",
  data () {
    return {
      form: {
        password: null,
        password_field: {
          type: "password",
          icon: "eye-slash",
        },
      },
      errors: {
        invalid: null,
      },
    };
  },
  mounted () {
    this.$root.$on("bv::modal::hide", () => {
      this.$router.push({
        name: "Login",
      });
    });
  },
  created () {
    if (this.$store.getters.loggedIn) {
      this.$router.push({
        name: "Home",
      });
    }
  },
  methods: {
    submitForm (e) {
      e.preventDefault();

      this.$store
        .dispatch("resetPassword", {
          password: this.form.password,
          resetPasswordToken: this.$route.query.resetPasswordToken,
          email: this.$route.query.email,
        })
        .then(
          () => {
            this.$bvModal.show("confirm");
          },
          (err) => {
            this.errors.invalid = this.$t(
              err.response.data.message[0].messages[0].id,
            );
          },
        );
    },
    showPassword () {
      if (this.form.password_field.type === "password") {
        this.form.password_field.type = "text";
        this.form.password_field.icon = "eye";
      } else {
        this.form.password_field.type = "password";
        this.form.password_field.icon = "eye-slash";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.hubu-bg-red {
  border: 1px solid $red;
}

.hubu-btn-red {
  background-color: $red;
  color: $white;
  font-weight: bold;
  border: 0px;
}

.btn-white {
  background-color: $white;
  color: $red;
  font-weight: bold;
  border: 1px solid $red;
}

header {
  background-color: $red;
  border-color: $red;
  font-size: 24px;
  color: $orange;
  font-weight: bold;
}
</style>